.campaign-placement-proof-container {
    display: flex;
    flex-direction: column;
}

.campaign-placement-proof-title {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.campaign-placement-proof-segmend {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
}

.campaign-placement-proof-segmend-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.campaign-placement-proof-steps {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 50px;
    margin-bottom: 10px;
}

.campaign-placement-proof-image-selection {
    background-color: #F5F5F5;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
}

.campaign-placement-proof-submit-button-container {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 25px;
    margin-bottom: 25px;
}