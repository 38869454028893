.product-picker-container {
    display: flex;
    flex-direction: row;
    border: 1px solid #6E6E6E;
    border-radius: 3px;
    opacity: 1;
    margin-top: 20px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}



.product-picker-arrow {
    margin-right: 15px;
    margin-left: 10px;
    margin-top: -8.5px;
}

.product-picker-text {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.product-list-title {
    margin-top: 40px;
    margin-left: 20px;
    margin-right: 20px;
  }

.product-list-container {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    margin-bottom: 40px;
}

.product-list-close-button {
    float: right;
    margin-right: 10px;
}

.product-list-content {
    margin-top: 20px;
}