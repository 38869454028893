.incentive-cart-container {
    display: flex;
    flex-direction: column;
    position: relative;
}

.incentive-cart-title {
    margin-left: 10px;
    margin-right: 10px;
    letter-spacing: 0px;
    margin-top: 30px;
}

.incentive-cart-list {
    margin-top: 20px;
    margin-bottom: 20px;
}

.incentive-cart-segmented-child-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
}


.incentive-cart-buttons {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.incentive-cart-success {
    position: absolute;
    align-self: center;
    z-index: 12;
    background-color: white;
    box-shadow: 0px 0px 30px #00000045;
    width: 80vw;
    height: 60vw;
    margin-left: 10vw;
    top: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
}


