.pzn-picker-container {
    display: flex;
    flex-direction: column;
    background-color: #F5F5F5;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
}

.pzn-picker-title {
    margin-top: 20px;
}

.pzn-picker-input {
    background-color: white;
    border-bottom: solid 1px #707070;
    margin-top: 20px;
}