.date-select-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border: 1px solid #6E6E6E;
    border-radius: 3px;
    opacity: 1;
    margin-left: 20px;
    margin-right: 20px;
    justify-content: space-between;
    align-items: center;
}



.date-select-arrow {
    width: 14px;
    height: 14px;
    transform: matrix(0.71, -0.71, 0.71, 0.71, 0, 0);
    /* UI Properties */
    border: 2px solid #6E6E6E;
    border-width: 0px 0px 2px 2px;
    opacity: 1;
    margin-right: 15px;
    margin-left: 10px;
    margin-top: -8.5px;
}

.date-select-text {
    text-align: left;
    margin-left: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}
