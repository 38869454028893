.cart-bar-container {
    background-color: #0096D1;
    box-shadow: 0px -3px 6px #00000014;
    display: flex;
    align-items: center;
    height: 70px;
}

.cart-bar-content {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.cart-bar-image-container {
    align-self: center;    
}