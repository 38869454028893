.login-background-container {
  background-color: lightcoral;
  background: url("/assets/images/LoginBackground@3x.png");
  background-size: cover;
}

.login-container {
  display: flex;
  flex-direction: column;
  margin-top: -10px;
  padding-top: 300px;
  padding-bottom: 110px;
}

.login-text-input {
  text-align: left;
  background-color: white;
  border-bottom: solid 2px #b7b7b7;
  margin-top: 5px;
  margin-left: 40px;
  margin-right: 40px;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 20px;
}

.login-button {
  margin-top: 20px;
  margin-left: 40px;
  margin-right: 40px;
}

.alternatives-container {
  margin-top: 20px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.login-seperation-line {
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
  height: 1px;
  border: 1px solid #0096D177;
}