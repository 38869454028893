

@font-face {
    font-family: OpenSans;
    src: url('/assets/fonts/OpenSans-Bold.ttf');
    font-weight: "bold";
}

@font-face {
    font-family: OpenSans;
    src: url('/assets/fonts/OpenSans-Italic.ttf');
    font-weight: 1;
}

@font-face {
    font-family: OpenSans;
    src: url('/assets/fonts/OpenSans-ExtraBold.ttf');
    font-weight: 900;
}

@font-face {
    font-family: OpenSans;
    src: url('/assets/fonts/OpenSans-Light.ttf');
    font-weight: 300;
}

@font-face {
    font-family: OpenSans;
    src: url('/assets/fonts/OpenSans-SemiBold.ttf');
    font-weight: 600;
}

@font-face {
    font-family: OpenSans;
    src: url('/assets/fonts/OpenSans-Regular.ttf');
    font-weight: "normal";
}

.text {
    font-family: OpenSans;
}