.news-container {
    display: flex;
    flex-direction: column;
}

.news-title {
    margin-top: 30px;
    text-align: center;
    margin-bottom: 30px;
}

.news-segmented-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;
}