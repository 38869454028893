.faq-container {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  margin-bottom: 40px;
  
}

.faq-title {
  margin-top: 40px;
  margin-left: 20px;
  margin-right: 20px;
}

.faq-close-button-container {
  position: relative;
}

.faq-close-button {
  float: right;
  margin-right: 10px;
}

.faq-section {
  margin-top: 40px;
}

.fag-section-header-container {
  display: flex;
  flex-direction: column;
}

.fag-section-header {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: space-between;
}

.fag-section-content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 40px;
}