.frame {
    height: 90%;
    margin: 40px auto;
    width: 580px;
    z-index: 999999;
    overflow: hidden;
    border-radius: 40px;
    box-shadow: 0px 0px 0px 11px #1f1f1f, 0px 0px 0px 13px #191919, 0px 0px 0px 20px #111;
  }
  
    .frame::before,
    .frame::after {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      z-index: 999999;
    }
  
    .frame::before {
        top: 0px;
        width: 46%;
        height: 30px;
        background-color: #1f1f1f;
        border-radius: 0px 0px 40px 40px;
        z-index: 999999;
    }
  
    .phone-speaker {
      top: 0px;
      left: 50%;
      transform: translate(-50%, 6px);
      height: 8px;
      width: 15%;
      background-color: #101010;
      border-radius: 8px;
      box-shadow: inset 0px -3px 3px 0px rgba(256, 256, 256, 0.2);
      display: block;
      position: absolute;
      color: transparent;
      z-index: 9999999;
    }
    
    .phone-camera {
      left: 30%;
      top: 0px;
      transform: translate(180px, 4px);
      width: 12px;
      height: 12px;
      background-color: #101010;
      border-radius: 12px;
      box-shadow: inset 0px -3px 2px 0px rgba(256, 256, 256, 0.2);
      display: block;
      position: absolute;
      color: transparent;
      z-index: 9999999;
    }
      .phone-camera::after {
        content: '';
        position: absolute;
        background-color: #2d4d76;
        width: 6px;
        height: 6px;
        top: 2px;
        left: 2px;
        top: 3px;
        left: 3px;
        display: block;
        border-radius: 4px;
        box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.5);
        z-index: 99999999;
      }
  