.heading {
  width: fit-content;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 30px;
}

.campaing-listing-description {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 30px;
}