
.button-container {
    display: flex;
    text-align: center;
    width: 100%;
}

.button-positiv {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 13px;
    padding-bottom: 13px;
    width: 100%;
    text-align: center;
    background: #0096D1 0% 0% no-repeat padding-box;
    border-radius: 3px;
    opacity: 1;
}

.button-positiv-bottom {
    height: 4px;
    width: 100%;
    vertical-align: bottom;
    background: #0078A7;
    border-radius: 0px 0px 3px 3px;
    opacity: 1;
}

.button-neutral {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    background-color: transparent;
    border: solid 2px #0096d1;
    padding-top: 13px;
    padding-bottom: 13px;
    background: transparent 0% 0% no-repeat padding-box;
    border-radius: 3px;
    opacity: 1;
}

.button-deactiviated {
    align-self: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 13px;
    padding-bottom: 13px;
    width: 100%;
    border: solid 2px #b7b7b7;
    text-align: center;
    border-radius: 1.5px;
    background: #b7b7b7 0% 0% no-repeat padding-box;
    border-radius: 3px;
    opacity: 1;
}