.campaign-sale-container {
    display: flex;
    flex-direction: column;
}

.campaign-sale-title {
    margin-left: 20px;
    margin-top: 37px;
}

.campaign-sale-pzn-product-selection-container {
    background-color: #F5F5F5;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
}

.campaign-sale-segmend {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
}


.campaign-sale-product-selection-container {
    display: flex;
    flex-direction: row;    
    justify-content: center;
}

.campaign-sale-product-selection-seperator {
    border: 1px solid #B7B7B7;
    opacity: 0.68;
    width: 100vw;
    height: 0;
    align-self: center;
}

.campaign-sale-submit-button-container {
    margin-left: 40px;
    margin-right: 40px;
}

.campaign-sale-segmented-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.campaign-sale-more-products-text {
    text-align: center;
    margin-bottom: 15px;
    margin-top: 30px;
}