
.campaign-category-tile-container {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 16px #0000001F;
    opacity: 1;
    width: 100%;
    height: 100%;
    justify-content: center;
}

.campaign-category-tile {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    justify-content: space-between;
}

.header-line {
    width: 100%;
    height: 0px;
    border: solid 1px #84bd00;
    margin-top: 0px;
}

.category-title {
    align-self: flex-start;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 5px;
    text-transform: uppercase;
    text-align: left;
    line-height: 16px;
    letter-spacing: 0.56px;
    opacity: 1;
    hyphens: auto;
}

.category-image {
    align-self: center;
    max-height: 67px;
    object-fit: contain;
    background: transparent;
}

@media only screen and (min-width: 580px) {
    [class="category-image"] {
        max-height: 125px;
    }
  }

.category-points {
    align-self: flex-end;
    margin-right: 10px;
    margin-bottom: 4px;
    letter-spacing: 0.52px;
    line-height: 18px;
    opacity: 1;
    
    
}