.campaign-display-proof-container {
    display: flex;
    flex-direction: column;
}

.campaign-display-proof-title {
    margin-left: 20px;
    margin-top: 37px;
}

.campaign-display-proof-segmend {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
}

.campaign-display-proof-segmend-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.campaign-display-proof-image-selection {
    background-color: #F5F5F5;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
}

.campaign-display-proof-submit-button-container {
    margin-left: 40px;
    margin-right: 40px;
}

.campaign-display-more-images-text {
    text-align: center;
    margin-bottom: 15px;
    margin-top: 20px;
}

