.incentive-details-container {
    display: flex;
    flex-direction: column;
}

.incentive-details-title {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 30px;
}

.incentive-details-description {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 40px;
}

.incentive-details-segmented-child-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
}

.incentive-details-text-child-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px; 
}

.incentive-details-points {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
}

.incentive-details-list {
    margin-bottom: 40px;
}

.incentive-details-button {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 50px;
    margin-bottom: 50px;
}