.campaign-display-container {
    display: flex;
    flex-direction: column;
}

.campaign-display-title {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 30px;
}

.campaign-display-description {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 40px;
}

.campaign-display-segments-container {
    margin-top: 40px;
}

.campaign-display-segments-text {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: space-between;
}



.campaign-display-submit-button {
    margin-left: 70px;
    margin-right: 70px;
    margin-top: 40px;
}

.campaign-display-info-text{
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
}

.campaign-display-info-button {
    margin-left: 70px;
    margin-right: 70px;
    margin-top: 40px;
    margin-bottom: 40px;
}