.incentive-category-tile-container {
    width: 100%;
    height: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 16px #0000001F;
    opacity: 1;}

.incentive-category-tile {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-between;
}

.header-line {
    width: 100%;
    height: 0px;
    border: solid 1px #84bd00;
    margin-top: 0px;
}

.incentive-category-title {
    margin-left: 10px;
    margin-top: 10px;
    margin-right: 5px;
    text-transform: uppercase;
    text-align: left;
    line-height: 18px;
    letter-spacing: 0.52px;
    opacity: 1;
    hyphens: auto;
}

.incentive-category-image {
    margin-bottom: 20px;
    align-self: bottom;
    max-height: 50%;
    background: transparent;
}

