.header-container {
  position: relative;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-image: url('/assets/images/HeaderBackground.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.logoImage {
  position: absolute;
  width: 50%;
  bottom: 0;
}

/* Brand */
.brand-container {
  position: relative;
  z-index: 1;
}

/* Content */
.content-image-main-container {
  position: relative;
  margin-top: -20px;
}

.content-image-container {
  position: relative;
}

.content-image-mask {
  position: absolute;
  z-index: 1;
  bottom: -3px;
  width: 100%;
  transform: scale(1.1);
}

.content-main-container {
  padding-top: 15px;
  padding-bottom: 20px;
  background-color: #f5f5f5;
}

.info-button-container {
  position: absolute;
  z-index: 2;
  right: 10px;
  bottom: -20px;
}
  
.back-button {
  position: absolute;
  left: 15px;
}

.back-button-extended {
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;
  top: 30px;
  width: 100%;
  height: auto;
}

.back-button-extended .arrow {
  position: absolute;
  margin-left: 15px;
}

.label-without-back-button {
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}

.back-button-extended .label {
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  top: 30px;
}

.header-content-container {
  padding-top: 15px; 
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
}

.header-content-navigation {
  margin-bottom: 10px;
}

.header-content {
  margin-bottom: 0px;
  margin-left: auto;
  margin-right: auto;
}

.header-content-image-mask {
  position: absolute;
  z-index: 1;
  bottom: -3px;
  width: 100%;
  transform: scale(1.4);
}