
.segment-text-container {
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
}

.segment-text-line {
    width: 100%;
    height: 0px;
    border: solid 0.5px #b7b7b7;
}

.segment-text-children {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    letter-spacing: 0;  
    text-transform: uppercase;
    opacity: 1;
}