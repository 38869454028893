
.details-container {
    display: flex;
    flex-direction: column;
}

.details-heading {
    width: fit-content;
    text-align: left;
    margin-left: 23px;
    margin-right: 23px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.details-descriptionText {
    text-align: left;
    margin-left: 23px;
    width: fit-content;
    margin-top: 20px;
}

.details-teaser-image-container {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    height: 150px;
}

.details-teaser-image-container div {
    height: fit-content;
    margin: auto;
}

/* .details-teaser-image-container img {
    height: auto;
    width: 100vw;
} */

.details-button {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
}