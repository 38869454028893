.placement-selection-tile-container {
    display: flex;
    flex-direction: row;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 16px #0000001F;
    opacity: 1;
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 20px;
    height: 137px;
}

.placement-selection-tile-image {
    height: 100%;
    object-fit: cover;
}

.placement-selection-tile-image-container {
    margin-top: 40px;
    margin-bottom: 40px;
    max-width: 90px;
    min-width: 90px;
    opacity: 1;
}

.placement-selection-tile-text-container {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    margin-bottom: 15px;
    justify-content: center;
    flex-grow: 2;
}

.placement-selection-tile-line {
    width: 0px;
    height: 100%;
    /* UI Properties */
    border: 1px solid #F5F5F5;
    opacity: 1;
    
}

.placement-selection-tile-counter-container {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-right: 20px;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 15px;
}

.placement-selection-tile-counting-container {
    align-self: flex-end;
    height: 100%;
    display: flex;
    min-width: 60px;
    max-width: 60px;
}

.placement-selection-tile-counter-number {
    opacity: 1;    
}

.placement-selection-tile-counter-arrow-down {
    width: 22px;
    height: 8px;
    opacity: 1;    
}

.placement-selection-tile-counter-arrow-up {
    width: 22px;
    height: 8px;
    opacity: 1;
}
