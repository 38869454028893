.campaign-training-proof-container {
    display: flex;
    flex-direction: column;
}

.campaign-training-proof-title {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 30px;
}

.campaign-training-proof-text {
    margin-left: 20px;
    margin-right: 20px;
}

.campaign-training-proof-info-button {
    margin-top: 35px;
    margin-left: 45px;
    margin-right: 45px;
}

.campaign-training-proof-segmented-text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
}

.campaign-training-proof-segmented-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.campaign-training-proof-submit-button {
    margin-top: 60px;
    margin-left: 65px;
    margin-right: 65px;
    margin-bottom: 60px;
}

.code-input {
    margin-top: 50px;
    margin-left: 90px;
    margin-right: 90px;
    background-color: #F5F5F5;

}