.incentive-overview-container {
    display: flex;
    flex-direction: column;
}

.incentive-title {
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
    margin-top: 10px;
}

.incentive-credits {
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
    margin-top: 20px;
}

.incentive-text {
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
    margin-top: 0px;
}

.incentive-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: 2.5%;
    width: 100%;
    margin-top: 50px;
}

.delivery-adress-info-container {
    background-color: #0096D1;
    box-shadow: 0px -3px 6px #00000014;
    display: flex;
    align-items: center;
    height: 70px;
}

.link-reset-incentive {
    text-decoration: none;
    color: inherit;
    width: 43%;
    aspect-ratio: 1/1;
    margin-top: 2.5%;
    margin-bottom: 2.5%;
    margin-left: 2.5%;
    margin-right: 2.5%;
    min-height: 138px;
}



  @media only screen and (min-width: 375px) {
    /* For mobile phones: */
    [class="link-reset-incentive"] {
        min-height: 161px;
    }
  }

  @media only screen and (min-width: 414px) {
    /* For mobile phones: */
    [class="link-reset-incentive"] {
        min-height: 178px;
    }
  }

  @media only screen and (min-width: 580px) {
    /* For mobile phones: */
    [class="link-reset-incentive"] {
        min-height: 249px;
    }
  }

.link-reset-incentive-ios {
    text-decoration: none;
    color: inherit;
    width: 43vw;
    height: 43vw;
    margin-top: 2.5%;
    margin-bottom: 2.5%;
    margin-left: 2.5%;
    margin-right: 2.5%;
}