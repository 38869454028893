.profile-segmented {
  display: flex;
  flex-direction: column;
}


.profile-segmented-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
}

.profile-subline {
  margin-top: 40px;
  margin-bottom: 35px;
  margin-left: 20px;
  margin-right: 20px;
}

.profile-save-button {
  margin-top: 40px;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 40px;
}

.profile-header-content-container {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  margin-top: 40px;
  height: 130px;
  justify-content: space-between;
}

.profile-header-content-pharmacy {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.profile-header-content-credits-container {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
}

.profile-header-content-credits {
  display: flex;
  flex-direction: column;
  margin-left: 25px;
}

.profile-header-title {
  text-align: center;
  margin-top: 25px;
}

.profile-header-line {
  width: 0px;
  height: 100%;
  /* UI Properties */
  border: 1px solid #B7B7B7;
  opacity: 1;
  
}