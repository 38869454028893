.campaign-training-container {
    display: flex;
    flex-direction: column;
}

.campaign-training-title {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 30px;
}

.campaign-training-text {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 30px;
}

.campaign-training-info-button {
    margin-top: 35px;
    margin-left: 45px;
    margin-right: 45px;
}

.campaign-training-segmented-text {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.campaign-training-submit-button {
    margin-top: 35px;
    margin-left: 45px;
    margin-right: 45px;
    margin-bottom: 35px;
}