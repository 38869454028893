.campaign-tile-container {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  height: 108px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  color: #707070;
}

.teaser-image {
  margin-left: 10px;
  margin-right: 20px;
  object-fit: contain;
  height: 80%;
  min-width: 80px;
  max-width: 80px;
}

.campaign-title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;
}

.link-reset {
  text-decoration: none;
  color: inherit;;
}