.image-thumbnail-container {
    width: 40vw;
    height: 40vw;
    margin: 10px auto;
    position: relative;
}

.image-thumbnail-image {
    position: absolute;
    margin-right: 7px;
    margin-top: 15px;
    margin-left: 7px;
    object-fit: cover;
    width: 35vw;
    height: 35vw;
}

.image-thumbnail-delete {
    position: relative;
    float: right;
    z-index: 1;
}