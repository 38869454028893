.campaign-placement-container {
    display: flex;
    flex-direction: column;
}

.campaign-placement-title {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 30px;
}

.campaign-placement-description {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 25px;
}

.campaign-placement-text-code {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 40px;
    margin-bottom: 15px;
}

.campaign-placement-segmented-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
}

.campaign-placement-button {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 40px;
    margin-bottom: 40px;
}