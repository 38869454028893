ion-content ion-toolbar {
  --background: translucent;
}

.spinner {
  margin: auto;
  width: fit-content;
}

.title {
  width: fit-content;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}

.subtitle {
  width: fit-content;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3px;
  margin-bottom: 30px;
}

.list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: 2.5%;
  width: 100%;
  margin-top: 50px;
}
