.registration-container {
  background: url("/assets/images/LoginBackgroundTransparent@3x.png");
  background-size: cover;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.registration-user-info-container {
  margin-top: 30px;
  margin-left: 40px;
  margin-right: 40px;
  display: flex;
  flex-direction: column;
}

.registration-text-input {
    text-align: left;
    background-color: white;
    border-bottom: solid 2px #b7b7b7;
    margin-top: 10px;

    padding-left: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 20px;
}

.registration-pharmacy-info-container {
  margin-top: 30px;
  margin-left: 40px;
  margin-right: 40px;
  display: flex;
  flex-direction: column;
}

.registration-submit-container {
  margin-top: 20px;
  margin-left: 40px;
  margin-right: 40px;
}


.registration-seperation-line {
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
  height: 1px;
  border: 1px solid #0096D177;
}

.registration-back-action-container {
  margin-top: 15px;
  margin-bottom: 70px;
  text-align: center;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}