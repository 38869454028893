.campaign-proof-done-container {
    display: flex;
    flex-direction: column;
}

.campaign-proof-done-title {
    margin-left: 20px;
    margin-top: 20px;
}

.campaign-proof-done-segmented-text-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
}

.campaign-proof-done-segmented-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.campaign-proof-done-check-container {
    justify-content: center;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
}
